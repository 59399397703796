import * as React from "react";
import { Link } from "react-router-dom";

function CustomerService(props) {
  return (
    <div className="bg-white flex flex-col pb-2">
      <div className="shadow-2xl bg-white self-stretch flex w-full flex-col pl-7 pr-20 py-3.5 items-start">
        <div className="flex w-[226px] max-w-full justify-between gap-5 pr-10 items-end">
          <Link to="/">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddf60bb5a49a7cd8685980fc6b89d0c28523731756168c7a7fefbbbde4e8200d?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full mt-5"
            />
          </Link>
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/423879eebd045a3438d4440acc702d6b570231481a4e42443f991eb5f8025c44?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-12 overflow-hidden self-stretch shrink-0 max-w-full"
          />
        </div>
        <div className="text-sky-900 text-base font-bold self-center whitespace-nowrap mt-2">
          Customer Service
        </div>
      </div>
      <div className="self-stretch flex w-full flex-col mt-72 px-3">
        <div className="self-stretch flex justify-between gap-3.5 items-start">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/51ce538391c0c888b122d0161c02b0e6ee8715be40ef74fd369d5e0cd9cb86af?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
          />
          <div className="text-sky-900 text-sm font-semibold bg-[#ACD4DE] self-stretch grow justify-center pl-3.5 pr-16 py-3.5 rounded-xl items-start">
            Welcome to Waste4Change customer service. I'm here to assist you.
          </div>
        </div>{" "}
        <div className="text-sky-900 text-sm font-semibold bg-[#ACD4DE] self-center w-60 max-w-full justify-center items-stretch mt-2.5 px-3.5 py-3.5 rounded-xl">
          Please click one of the following options which suits your needs best
        </div>{" "}
        <div className="text-slate-500 text-xs font-semibold whitespace-nowrap ml-9 mt-1.5 self-start">
          Monday 10.00 AM
        </div>{" "}
        <div className="self-stretch flex items-stretch justify-between gap-3 mt-7">
          <div className="text-sky-900 text-sm font-semibold whitespace-nowrap bg-[#ACD4DE] grow justify-center pl-4 pr-16 py-3 rounded-2xl items-start">
            General Info
          </div>{" "}
          <div className="text-sky-900 text-sm font-semibold whitespace-nowrap bg-[#ACD4DE] grow justify-center pl-4 pr-16 py-3 rounded-2xl items-start">
            Quotation
          </div>
        </div>{" "}
        <div className="self-stretch flex items-stretch justify-between gap-3 mt-3">
          <Link to="/customer-service/order">
            <div className="text-sky-900 text-sm font-semibold whitespace-nowrap bg-[#ACD4DE] aspect-[2] justify-center items-stretch px-4 py-3.5 rounded-2xl">
              Order
            </div>{" "}
          </Link>
          <div className="text-sky-900 text-sm font-semibold whitespace-nowrap bg-[#ACD4DE] grow justify-center items-stretch px-4 py-3 rounded-2xl">
            Account & Verification
          </div>{" "}
          <div className="text-sky-900 text-sm font-semibold whitespace-nowrap bg-[#ACD4DE] justify-center items-stretch px-4 py-3.5 rounded-2xl">
            Content
          </div>
        </div>
      </div>{" "}
      <div className="border bg-white self-stretch flex w-full items-center justify-between gap-5 mt-3 pl-3.5 pr-12 py-1.5 rounded-3xl border-solid border-slate-500">
        <div className="flex items-stretch gap-3.5 my-auto">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/77662bed371d62e6e461c12da3cdae14261aba99ffcf4286415d1acb37e75276?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
          />{" "}
          <div className="text-slate-400 text-base font-semibold self-center grow whitespace-nowrap my-auto">
            Message...
          </div>
        </div>{" "}
        <div className="self-stretch flex items-center justify-between gap-1.5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d0129cc61a1544437984ab137625b80456c0c56f55ba32d1b20d095357785737?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full my-auto"
          />{" "}
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-10 overflow-hidden self-stretch shrink-0 max-w-full rounded-[50%]"
          />
        </div>
      </div>{" "}
      <div className="rounded bg-stone-700 self-center flex w-[127px] shrink-0 h-1 flex-col mt-5" />
    </div>
  );
}

export default CustomerService;