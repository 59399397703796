import * as React from "react";
import '../App.css';
import { Link } from "react-router-dom";

function Profile() {
  return (
    <div className="bg-white flex flex-col">
      <img
        loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ed5f5e66-8968-42ee-867b-0b4a64ce35c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
        className="aspect-[5.86] object-contain object-center w-full fill-cyan-600 overflow-hidden self-stretch"
      />
      <div className="self-stretch flex w-full items-stretch justify-between gap-5 mt-[-2px]">
        <div className="flex grow basis-[0%] flex-col items-stretch">
          <div className="flex items-stretch justify-between gap-3.5">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/56091d12-22f9-4182-b148-eb42d64216a9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-[1.04] object-contain object-center w-[50px] fill-lime-400 overflow-hidden shrink-0 max-w-full"
            />
            <div className="text-sky-900 text-xl font-bold leading-7 grow whitespace-nowrap mt-6 self-end">
              My Account
            </div>
          </div>
          <div className="flex items-stretch justify-between gap-5 mt-3.5">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/85c88cab-f7a9-4e1f-8855-64451f2a13bf?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-square object-contain object-center w-[89px] overflow-hidden shrink-0 max-w-full"
            />
            <div className="self-center flex grow basis-[0%] flex-col items-stretch my-auto px-5">
              <div className="text-sky-900 text-2xl font-bold leading-8 whitespace-nowrap -mr-5">
                Jane Doe{" "}
              </div>
              <div className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap -mr-5 mt-2.5">
                Microsoft Co.
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-10 px-9 max-md:mt-10 max-md:px-5">
            <div className="text-sky-900 text-xl font-bold leading-7 self-stretch whitespace-nowrap">
              Account Settings
            </div>
            <div className="flex w-[121px] max-w-full items-stretch gap-3.5 ml-4 mt-9 max-md:ml-2.5">
            <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/08b1ea49-e18a-44fd-a8c6-259044e22278?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
                className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
              />
              <div className="text-sky-900 text-base font-semibold leading-5 self-center grow whitespace-nowrap my-auto">
                Pick up Address
              </div>
            </div>
            <div className="flex w-[121px] max-w-full items-stretch gap-3.5 ml-4 mt-9 max-md:ml-2.5">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ce5696e0-1375-47f6-b8da-3dcaa7a85cd2?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
                className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
              />
              <div className="text-sky-900 text-base font-semibold leading-5 self-center grow whitespace-nowrap my-auto">
                Notification
              </div>
            </div>
          </div>
        </div>
        <div className="flex basis-[0%] flex-col items-center mt-5 self-start">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/90bd25c4-29da-4444-958a-139f862885ff?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.13] object-contain object-center w-[27px] overflow-hidden"
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/03fbe4da-4966-450f-b1c2-2ba68c22c929?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.13] object-contain object-center w-[27px] overflow-hidden mt-10"
          />
        </div>
      </div>
      <div className="self-stretch flex w-full flex-col items-stretch mt-9 px-6 max-md:px-5">
        <div className="flex w-[122px] max-w-full items-stretch gap-3.5 ml-4 self-start max-md:ml-2.5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e92331f8-0428-4d1c-a171-fd4255a09791?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
          />
          <div className="text-sky-900 text-base font-semibold leading-5 self-center grow whitespace-nowrap my-auto">
            Help Center
          </div>
        </div>
        <Link to="/customer-service">
          <div className="flex w-[162px] max-w-full items-stretch gap-3.5 ml-4 mt-9 self-start max-md:ml-2.5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/46c5a5b3-479d-40a6-a501-e63b4e1f75fc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
            />
            <div className="text-sky-900 text-base font-semibold leading-5 self-center grow whitespace-nowrap my-auto">
              Customer Service
            </div>
          </div>
        </Link>
        <div className="text-white text-xl font-bold leading-7 whitespace-nowrap bg-cyan-600 items-center mt-14 px-5 py-5 rounded-xl max-md:mt-10">
          Log Out
        </div>
      </div>
      <div className="self-stretch flex w-full justify-between gap-0 mt-14 px-5 max-md:justify-center max-md:mt-10">
        <Link to="/dashboard">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.31] object-contain object-center w-[84px] overflow-hidden shrink-0 max-w-full mt-4 self-start"
          />
        </Link>
        <div className="bg-white flex grow basis-[0%] flex-col items-center mt-6 pl-5 pr-6 py-2.5 rounded-lg self-start max-md:px-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/311ebf48-5ccf-4559-82de-cd717d8c9c4e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.25] object-contain object-center w-[30px] overflow-hidden"
          />
          <div className="text-slate-300 text-xs font-semibold leading-3 self-stretch whitespace-nowrap">
            Collect
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8842639-ae43-4cb2-b2f3-ae372a1289c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-square object-contain object-center w-16 overflow-hidden shrink-0 max-w-full self-start"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full mt-6 rounded-lg"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/19e65621-3d0c-4faa-a707-35c0895aae13?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-full shadow-sm overflow-hidden shrink-0 flex-1 mt-6 rounded-lg"
        />
      </div>
    </div>
  );
}

export default Profile;