import * as React from "react";
import { Link, Navigate } from "react-router-dom";
import axiosClient from "../axios-client";
import { useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useEffect } from "react";

function Login() {
  const { user, token, notification, setUser, setToken } = useStateContext();
  const emailRef = useRef();
  const passwordRef = useRef();

  const [errors, setErrors] = useState(null);

  const onSubmit = (ev) => {
    ev.preventDefault();
    const payload = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };
    setErrors(null);
    axiosClient
      .post("/login", payload)
      .then(({ data }) => {
        setToken(data.token);
        setUser(data.user);
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status == 422) {
          if (response.data.errors) {
            setErrors(response.data.errors);
          } else {
            setErrors({
              email: [response.data.message],
            });
          }
        }
      });
  };
  return (
    <section className="bg-white flex flex-col">
      <div className="self-stretch flex w-full flex-col mt-7 px-8 max-md:px-5">
        <form onSubmit={onSubmit}>
          <h1 className="text-black text-2xl font-bold leading-8 self-stretch whitespace-nowrap">
            Login
          </h1>
          {errors && (
            <div className="alert">
              {Object.keys(errors).map((key) => (
                <p key={key}>{errors[key][0]}</p>
              ))}
            </div>
          )}
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcdf6574-84a8-495e-9804-0c94bb83cbef?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[0.64] object-contain object-center w-[108px] overflow-hidden self-center max-w-full mt-8"
            alt=""
          />
          <label
            htmlFor="email"
            className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-6 mt-10 self-start max-md:ml-2.5"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            ref={emailRef}
            className="text-slate-400 text-base font-semibold leading-5 whitespace-nowrap border bg-white w-[310px] ml-5 mt-2.5 px-4 py-4 rounded-xl border-solid border-slate-500 self-start max-md:ml-2.5"
            placeholder="Email"
          />
          <label
            htmlFor="password"
            className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-6 mt-4 self-start max-md:ml-2.5"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            ref={passwordRef}
            className="text-slate-400 text-base font-semibold leading-5 whitespace-nowrap border bg-white w-[310px] ml-5 mt-2.5 px-4 py-4 rounded-xl border-solid border-slate-500 self-start max-md:ml-2.5"
            placeholder="Password"
          />
          <a
            href="#"
            className="text-slate-400 text-xs font-semibold leading-4 underline whitespace-nowrap mt-2.5 self-end"
          >
            Forgot Password
          </a>
          <button className="text-white text-xl font-bold leading-7 whitespace-nowrap bg-cyan-600 self-center w-full max-w-[299px] items-center mt-6 px-5 py-4 rounded-xl">
            Login
          </button>
        </form>
      </div>
      <div className="text-slate-400 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-3">
        Or
      </div>
      <div className="text-cyan-600 text-xs font-semibold leading-4 underline self-center whitespace-nowrap mt-3.5">
        <span className="font-semibold text-slate-400">
          Don’t have an account?{" "}
        </span>
        <Link to="/register" className="font-bold text-cyan-600">
          Sign Up
        </Link>
      </div>
      <div className="text-slate-400 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-9">
        Terms | Privacy & Policy
      </div>
    </section>
  );
}

export default Login;
