import * as React from "react";
import { Link } from "react-router-dom";

function Content(props) {
  return (
    <div className="bg-white flex max-w-[480px] w-full flex-col mx-auto pb-2">
      <img
        loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/71fdc13b4a698ce8a955b80ae1584084c132ece613b064b7556d265f020ea0bc?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
        className="aspect-[5.86] object-contain object-center w-full fill-cyan-600 overflow-hidden self-stretch"
      />
      <div className="self-stretch flex items-stretch justify-between gap-3.5">
        <Link to="/dashboard">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/20001d08a469d6dc5a7ebc26afef7a79d488099fc470714f493f7fc21277e0dd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.04] object-contain object-center w-[50px] fill-lime-400 overflow-hidden shrink-0 max-w-full"
          />
        </Link>
        <div className="text-sky-900 text-xl font-bold grow shrink basis-auto mt-6 self-end">
          Content Output
        </div>
      </div>
      <div className="self-stretch flex w-full flex-col mt-3.5 px-10 items-start">
        <div className="text-sky-900 text-base font-semibold whitespace-nowrap ml-3">
          Month
        </div>
        <div className="border bg-white self-stretch flex items-center justify-between gap-5 mt-2.5 px-4 py-2.5 rounded-xl border-solid border-slate-500">
          <div className="text-slate-400 text-base font-semibold my-auto">
            November 2023
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a24de752502b897d62362520376f310b097ac4b98a24d84efe50339a13f344b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden self-stretch shrink-0 max-w-full"
          />
        </div>
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a459b5dc787c6eb35957ded40a817282352f2a780e620487a28c31c41a27f398?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[0.81] object-contain object-center w-full overflow-hidden self-stretch mt-10"
        />
      </div>
      <div className="self-stretch flex w-full items-start justify-between gap-0 mt-16 px-5">
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/02621a127633dd9d56d87a71a18a3f1281fdd4fa98d7eb513d6e21b3dda67654?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-full overflow-hidden shrink-0 flex-1 mt-6 rounded-lg"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/972c6c3827850d5730ccdbdcbbf8ea0a1ef1e0201d946990221d60d19ab5bed7?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-full overflow-hidden shrink-0 flex-1 mt-6 rounded-lg"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/af488d7bce2296634925a4ec664262d84348ddcbdbcb9fc07d6526563b7c0d08?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-square object-contain object-center w-full overflow-hidden shrink-0 flex-1"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f1a8ae59ca17ec114712267340a56876aa81e66afbcc3749d777710cc367ae97?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-full overflow-hidden shrink-0 flex-1 mt-6 rounded-lg"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8a8109b8af52d98121a044cc2fc8f9ad043b435828f512179a21218b419f33e6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-full overflow-hidden shrink-0 flex-1 mt-6 rounded-lg"
        />
      </div>
      <div className="rounded bg-stone-700 self-center flex w-[127px] shrink-0 h-1 flex-col mt-6" />
    </div>
  );
}

export default Content;