import * as React from "react";
import '../App.css';
import { Link } from "react-router-dom";

function Order() {
  return (
    <section className="bg-white flex flex-col">
      <img
        loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0e5d93a9-d8e6-4a7d-baf1-033fb0f4afc0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"className="aspect-[5.86] object-contain object-center w-full fill-cyan-600 overflow-hidden self-stretch"
        alt=""
      />
      <div className="flex w-[116px] max-w-full items-stretch gap-3.5 px-5 self-start">
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/58db21ce-2746-43b5-b745-a243081daf80?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"className="aspect-[1.04] object-contain object-center w-full fill-lime-400 overflow-hidden shrink-0 flex-1"
          alt=""
        />
        <div className="text-sky-900 text-xl font-bold leading-7 grow whitespace-nowrap mt-6 self-end">
          Order
        </div>
      </div>
      <div className="self-stretch flex w-full flex-col mt-4 px-10 max-md:px-5">
        <div className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-3 self-start max-md:ml-2.5">
          Date
        </div>
        <div className="border bg-white self-stretch flex justify-between gap-5 mt-2.5 pl-4 pr-3.5 py-2.5 rounded-xl border-solid border-slate-500">
          <div className="text-slate-400 text-base font-semibold leading-5 mt-2">
            DD/MM/YY
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4954c255-27a6-4d1c-89b2-0c1fae7f02aa?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden self-stretch shrink-0 max-w-full"
            alt=""
          />
        </div>
        <div className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-3 mt-4 self-start max-md:ml-2.5">
          Time
        </div>
        <div className="text-slate-400 text-base font-semibold leading-5 whitespace-nowrap border bg-white self-stretch mt-2.5 px-4 py-4 rounded-xl border-solid border-slate-500">
          00.00 AM
        </div>
        <div className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-3 mt-4 self-start max-md:ml-2.5">
          Amount
        </div>
        <div className="text-slate-400 text-base font-semibold leading-5 border bg-white self-stretch mt-2.5 pt-4 pb-3 px-4 rounded-xl border-solid border-slate-500">
          XX Kg
        </div>
        <div className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-3 mt-4 self-start max-md:ml-2.5">
          Pick Up Location
        </div>
        <div className="text-slate-400 text-base font-semibold leading-5 border bg-white self-stretch mt-1.5 px-4 py-4 rounded-xl border-solid border-slate-500">
          Jl. Alun Alun Utara, Bekasi
        </div>
        <div className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-3 mt-4 self-start max-md:ml-2.5">
          Map
        </div>
        <div className="flex-col overflow-hidden self-stretch relative flex aspect-[2.9029126213592233] mt-1.5 pt-12 pb-2.5 px-5">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a6fad8ca-d689-400c-a17c-f85147038e4b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"className="absolute z-[-1] h-full w-full object-cover object-center inset-0"
            alt=""
          />
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/094dd486-3be7-4ecd-acea-d3b325eec078?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[0.71] object-contain object-center w-[17px] overflow-hidden max-w-full mt-5 max-md:-mr-2"
            alt=""
          />
        </div>
        <Link to="/dashboard" className="text-white text-base font-bold leading-5 whitespace-nowrap bg-cyan-600 self-center w-[223px] max-w-full items-center mt-6 px-5 py-5 rounded-xl">
          Submit
        </Link>
      </div>
      <div className="self-stretch flex w-full justify-between gap-0 mt-14 px-5 max-md:justify-center max-md:mt-10">
        <Link to="/dashboard">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/518a8fe4-356f-4001-b324-640e81dbb19c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.31] object-contain object-center w-[84px] overflow-hidden shrink-0 max-w-full mt-4 self-start"
          />
        </Link>
        <div className="bg-white flex grow basis-[0%] flex-col items-center mt-6 pl-5 pr-6 py-2.5 rounded-lg self-start max-md:px-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/311ebf48-5ccf-4559-82de-cd717d8c9c4e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.25] object-contain object-center w-[30px] overflow-hidden"
          />
          <div className="text-slate-300 text-xs font-semibold leading-3 self-stretch whitespace-nowrap">
            Collect
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8842639-ae43-4cb2-b2f3-ae372a1289c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-square object-contain object-center w-16 overflow-hidden shrink-0 max-w-full self-start"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full mt-6 rounded-lg"
        />
        <Link to="/profile">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.5] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full mt-6 rounded-lg"
            alt="Your alt text"
          />
        </Link>
      </div>
    </section>
  );
}

export default Order;