import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="bg-white flex flex-col">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/06e47a6f-9430-4faa-8781-9d2a15579e5d?"
        className="aspect-[0.64] object-contain object-center w-[124px] overflow-hidden self-center max-w-full mt-32 max-md:mt-10"
        alt="Your alt text"
      />
      <div className="flex flex-col items-center mt-20 max-md:mt-10">
        <Link
          to="/login"
          className="text-white text-xl font-bold leading-7 bg-cyan-600 w-full max-w-[299px] px-5 py-4 rounded-xl mb-3 text-center"
        >
          Login
        </Link>
        <div className="text-slate-400 text-xs font-semibold leading-4 mb-3">
          Don’t have an account?
        </div>
        <Link
          to="/register"
          className="text-teal-400 text-xl font-bold leading-7 bg-sky-100 w-full max-w-[299px] px-5 py-5 rounded-xl text-center"
        >
          Create Account
        </Link>
      </div>
      <div className="text-slate-400 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-52 max-md:mt-10 mb-3">
        Terms | Privacy & Policy
      </div>
    </div>
  );
}

export default Home;
