import * as React from "react";
import '../App.css';
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div className="bg-white flex flex-col items-center">
      <img
        loading="lazy"
        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/82be76eb-ce78-4577-b82b-5e7b2d39323f?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
        className="aspect-[5.86] object-contain object-center w-full fill-cyan-600 overflow-hidden self-stretch"
      />
      <div className="bg-sky-100 self-stretch flex w-full flex-col">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/98906717-7acc-4c2a-8479-c3d46dcfbab4?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[0.63] object-contain object-center w-[30px] fill-lime-400 overflow-hidden max-w-full"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7c7fca69-384f-4485-8060-2d43d2459273?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[2.21] object-contain object-center w-full justify-center items-center opacity-40 overflow-hidden self-stretch"
        />
      </div>
      <div className="flex w-full max-w-[316px] items-stretch justify-between gap-5 mt-7">
        <div className="flex grow basis-[0%] flex-col items-stretch px-5">
          <div className="text-sky-900 text-2xl font-bold leading-8 whitespace-nowrap -mr-5">
            Services
          </div>
        </div>
        <div className="flex grow basis-[0%] flex-col mt-1.5 px-5 self-start">
          <div className="flex items-center gap-1 max-md:ml-0">
            <div className="text-slate-500 text-sm font-semibold leading-5 my-auto">
              See More
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f514ce11-5c93-4348-9083-51749241c31e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-square object-contain object-center w-4 overflow-hidden self-stretch shrink-0 max-w-full"
            />
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-[312px] items-stretch justify-between gap-5 mt-7 px-5 max-md:justify-center">
        <div className="flex grow basis-[0%] flex-col items-stretch self-start">
          <div className="shadow-sm bg-white flex flex-col items-center p-4 rounded-xl">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a32cb6ac-fa14-4353-9fe9-18ca1521c9cb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100"
              className="aspect-square object-contain object-center w-[60px] h-[60px] overflow-hidden"
            />
          </div>
          <div className="text-sky-900 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-2.5">
            How-To
          </div>
        </div>
        <div className="flex grow basis-[0%] flex-col items-stretch">
          <div className="shadow-sm bg-white flex flex-col px-5 py-4 rounded-xl">
            <Link to="/order">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5907e05f-2f95-494b-bd5f-3c295369e50c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100"
                className="aspect-square object-contain object-center w-[60px] h-[60px] overflow-hidden max-md:ml-px"
              />
            </Link>
          </div>
          <div className="text-sky-900 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-2">
            Order
          </div>
        </div>
        <div className="flex grow basis-[0%] flex-col items-stretch self-start">
          <div className="shadow-sm bg-white flex flex-col items-center p-4 rounded-xl">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb0f8342-6f3e-403b-a6ba-11fde96f70e0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-square object-contain object-center w-[60px] h-[60px] overflow-hidden"
            />
          </div>
          <div className="text-sky-900 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-2">
            Waste Track
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-[312px] items-stretch justify-between gap-5 mt-7 px-5 max-md:justify-center">
        <Link to="/content">
          <div className="flex grow basis-[0%] flex-col items-stretch self-start">
            <div className="shadow-sm bg-white flex flex-col items-center p-4 rounded-xl">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b25f8367-1113-4c34-b6ca-f338b0bab88b?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
                className="aspect-square object-contain object-center w-[60px] h-[60px] overflow-hidden"
              />
            </div>
            <div className="text-sky-900 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-2.5">
              Content
            </div>
          </div>
        </Link>
        <Link to="/customer-service">
          <div className="flex grow basis-[0%] flex-col items-stretch">
            <div className="shadow-sm bg-white flex flex-col px-5 py-4 rounded-xl">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6dfb9daa-0234-4ffc-9da8-0175185aecfb?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
                className="aspect-square object-contain object-center w-[60px] h-[60px] overflow-hidden max-md:ml-px"
              />
            </div>
              <div className="text-sky-900 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-2">
                Support
              </div>
          </div>
        </Link>
        <div className="flex grow basis-[0%] flex-col items-stretch self-start">
          <div className="shadow-sm bg-white flex flex-col items-center p-4 rounded-xl">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/15c8a281-b772-48ff-a645-7ace8be23501?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-square object-contain object-center w-[60px] h-[60px] overflow-hidden"
            />
          </div>
          <div className="text-sky-900 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-2">
            Other
          </div>
        </div>
      </div>
      <div className="self-stretch flex w-full justify-between gap-0 mt-14 px-5 max-md:justify-center max-md:mt-10">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/28c7f501-86cc-4fb5-94ff-2a14b988ced9?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.31] object-contain object-center w-[84px] overflow-hidden shrink-0 max-w-full mt-4 self-start"
        />
        <div className="bg-white flex grow basis-[0%] flex-col items-center mt-6 pl-5 pr-6 py-2.5 rounded-lg self-start max-md:px-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/311ebf48-5ccf-4559-82de-cd717d8c9c4e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.25] object-contain object-center w-[30px] overflow-hidden"
          />
          <div className="text-slate-300 text-xs font-semibold leading-3 self-stretch whitespace-nowrap">
            Collect
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8842639-ae43-4cb2-b2f3-ae372a1289c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-square object-contain object-center w-16 overflow-hidden shrink-0 max-w-full self-start"
        />
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/008370c8-4dd5-49ec-81b7-af1c5dce664c?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
          className="aspect-[1.5] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full mt-6 rounded-lg"
        />
        <Link to="/profile">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/cb2271c9-da40-4f2c-88b3-e77cf328734e?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-[1.5] object-contain object-center w-[75px] overflow-hidden shrink-0 max-w-full mt-6 rounded-lg"
            alt="Your alt text"
          />
        </Link>
      </div>
    </div>
  );
}

export default Dashboard;