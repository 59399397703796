import * as React from "react";
import { Link } from "react-router-dom";

function CustomerServiceOrder(props) {
  return (
    <div className="bg-white flex flex-col pb-2">
      <div className="shadow-2xl bg-white self-stretch flex w-full flex-col pl-7 pr-20 py-3.5 items-start">
        <div className="flex w-[226px] max-w-full justify-between gap-5 pr-10 items-end">
          <Link to="/customer-service">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c262a067e1538797419e482b599a1fe24e1841c812918a8dcfd18e84a99ab32d?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full mt-5"
            />
          </Link>
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0b8b007bde8227d1bd531bf4ab237e41708476b3f2d4615714b1fa7afb8bd71a?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-12 overflow-hidden self-stretch shrink-0 max-w-full"
          />
        </div>
        <div className="text-sky-900 text-base font-bold self-center whitespace-nowrap mt-2">
          Customer Service
        </div>
      </div>
      <div className="self-stretch flex w-full flex-col mt-80 px-3">
        <div className="self-stretch flex justify-between gap-3.5 items-start">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4bb0d6e7c64564a6630231f92d9855a3907bf1532532e7ae39d0fb4a1a59dbd?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
          />
          <div className="text-sky-900 text-sm font-semibold bg-[#ACD4DE] self-stretch grow justify-center pl-3.5 pr-16 py-3.5 rounded-xl items-start">
            Welcome to Waste4Change customer service. I'm here to assist you.
          </div>
        </div>{" "}
        <div className="text-sky-900 text-sm font-semibold bg-[#ACD4DE] self-center w-60 max-w-full justify-center items-stretch mt-2.5 px-3.5 py-3.5 rounded-xl">
          Please click one of the following options which suits your needs best
        </div>{" "}
        <div className="text-slate-500 text-xs font-semibold whitespace-nowrap ml-9 mt-1.5 self-start">
          Monday 10.00 AM
        </div>{" "}
        <div className="flex items-stretch gap-3 mt-4 self-end">
          <div className="flex grow basis-[0%] flex-col items-stretch">
            <div className="text-white text-sm font-semibold whitespace-nowrap bg-[#41B0CA] aspect-[2.1142857142857143] justify-center items-stretch pl-4 pr-6 py-3.5 rounded-xl">
              Order
            </div>{" "}
            <div className="text-slate-500 text-xs font-semibold whitespace-nowrap mt-1.5">
              Monday 10.01 AM
            </div>
          </div>{" "}
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/128980193c8bdad2f84bd39a73ef83b2f6e60f91a23e396a762afae4e812b556?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
          />
        </div>
      </div>{" "}
      <div className="border bg-white self-stretch flex w-full items-center justify-between gap-5 mt-7 pl-3.5 pr-12 py-1.5 rounded-3xl border-solid border-slate-500">
        <div className="flex items-stretch gap-3.5 my-auto">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/eee7e2c83eff3f3c22c76382d6499f8f6749308ab0115d228a933398d12306a5?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
          />{" "}
          <div className="text-slate-400 text-base font-semibold self-center grow whitespace-nowrap my-auto">
            Message...
          </div>
        </div>{" "}
        <div className="self-stretch flex items-center justify-between gap-1.5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b0da329156d611376d6a70a1e3e0e52ad72a074f36a139b1a2298db327efa3c0?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full my-auto"
          />{" "}
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f21d7f06d81bf453530f54b6487215fe0aa2f8389b148ca5c8dae4c4420d8b90?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-10 overflow-hidden self-stretch shrink-0 max-w-full rounded-[50%]"
          />
        </div>
      </div>{" "}
      <div className="rounded bg-stone-700 self-center flex w-[127px] shrink-0 h-1 flex-col mt-5" />
    </div>
  );
}

export default CustomerServiceOrder;