import * as React from "react";
import { Link, Navigate } from "react-router-dom";
import axiosClient from "../axios-client";
import { useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useEffect } from "react";

function Register() {
  const { user, token, setUser, setToken } = useStateContext();
  const [errors, setErrors] = useState(null);

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmationRef = useRef();

  const onSubmit = (ev) => {
    ev.preventDefault();
    const payload = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
      password_confirmation: passwordConfirmationRef.current.value,
    };
    axiosClient
      .post("/register", payload)
      .then(({ data }) => {
        setToken(data.token);
        setUser(data.user);
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status == 422) {
          setErrors(response.data.errors);
        }
      });
  };
  return (
    <section className="bg-white flex flex-col items-stretch">
      <div className="flex w-full flex-col mt-7 mb-2 pl-5 pr-10 max-md:pr-5">
        <div className="self-stretch flex items-stretch justify-between gap-5">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/72832671-b31a-45a5-873b-6db80cf1d5c6?apiKey=bbc3c1729d0a44aeb1a84f59d4199917&"
            className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
            alt=""
          />
          <h1 className="text-black text-2xl font-bold leading-8 grow whitespace-nowrap">
            Sign Up
          </h1>
        </div>
        <form onSubmit={onSubmit}>
          <h2 className="text-cyan-600 text-center text-4xl font-bold leading-10 self-center max-w-[251px] mt-11 max-md:mt-10">
            Create New Account
          </h2>
          {errors && (
            <div className="alert">
              {Object.keys(errors).map((key) => (
                <p key={key}>{errors[key][0]}</p>
              ))}
            </div>
          )}
          <label
            htmlFor="name"
            className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-8 mt-11 self-start max-md:ml-2.5 max-md:mt-10"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            ref={nameRef}
            className="text-slate-400 text-base font-semibold leading-5 whitespace-nowrap border bg-white w-[299px] ml-5 mt-2.5 px-4 py-4 rounded-xl border-solid border-slate-500 self-start max-md:ml-2.5"
            placeholder="Your name"
          />
          <label
            htmlFor="email"
            className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-8 mt-4 self-start max-md:ml-2.5"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            ref={emailRef}
            className="text-slate-400 text-base font-semibold leading-5 whitespace-nowrap border bg-white w-[299px] ml-5 mt-2.5 px-4 py-4 rounded-xl border-solid border-slate-500 self-start max-md:ml-2.5"
            placeholder="Email"
          />
          <label
            htmlFor="password"
            className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-8 mt-4 self-start max-md:ml-2.5"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            ref={passwordRef}
            className="text-slate-400 text-base font-semibold leading-5 whitespace-nowrap border bg-white w-[299px] ml-5 mt-2.5 px-4 py-4 rounded-xl border-solid border-slate-500 self-start max-md:ml-2.5"
            placeholder="Password"
          />
          <label
            htmlFor="password"
            className="text-sky-900 text-base font-semibold leading-5 whitespace-nowrap ml-8 mt-4 self-start max-md:ml-2.5"
          >
            Password Confirmation
          </label>
          <input
            type="password"
            id="password-confirmation"
            ref={passwordConfirmationRef}
            className="text-slate-400 text-base font-semibold leading-5 whitespace-nowrap border bg-white w-[299px] ml-5 mt-2.5 px-4 py-4 rounded-xl border-solid border-slate-500 self-start max-md:ml-2.5"
            placeholder="Password"
          />
          <button className="text-white text-xl font-bold leading-7 whitespace-nowrap bg-cyan-600 items-center ml-5 mt-8 px-5 py-5 rounded-xl self-start max-md:ml-2.5">
            Create Account
          </button>
          <div className="text-slate-400 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-3">
            Or
          </div>
          <div className="text-cyan-600 text-xs font-semibold leading-4 underline self-center whitespace-nowrap mt-3.5">
            <span className="font-semibold text-slate-400">
              Already have an account?{" "}
            </span>
            <Link to="/login" className="font-bold text-cyan-600">Login</Link>
          </div>
        </form>
        <div className="text-slate-400 text-xs font-semibold leading-4 self-center whitespace-nowrap mt-11 max-md:mt-10">
          Terms | Privacy & Policy
        </div>
      </div>
    </section>
  );
}

export default Register;